.Footer {
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  color: #ddd;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: right;
  font-family: "EB Garamond", serif;
  text-shadow: 1px 1px 0 #333;

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (max-width: 768px) {
    writing-mode: vertical-rl;
    font-size: 0.6rem;
    line-height: 0.8rem;
  }

  p {
    margin: 0;

    a {
      color: inherit;
      text-decoration: none;
    }

  }
}
  