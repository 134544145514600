.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.gallery {
  color: #333;
  margin-right: 5px;
}
.gallery h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 20px 0 10px;
}
@media (max-width: 768px) {
  .gallery h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media (max-width: 480px) {
  .gallery h2 {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}
.gallery .sectionCtn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.gallery .sectionCtn .item {
  width: 50%;
  padding-bottom: 33.33%;
  box-sizing: border-box;
  position: relative;
  transition: border-width 0.3s;
  overflow: hidden;
}
@media (max-width: 768px) {
  .gallery .sectionCtn .item {
    width: 100%;
    padding-bottom: 66.67%;
    margin-bottom: 10px;
  }
}
.gallery .sectionCtn .item .itemBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #666;
}
.gallery .sectionCtn .item .itemBg:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
.gallery .sectionCtn .item .count {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  font-size: 0.8rem;
  border-radius: 5px;
}
.gallery .sectionCtn .item .title {
  position: absolute;
  font-family: var(--secondary-font);
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  .gallery .sectionCtn .item .title {
    padding: 5px;
  }
}
