.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.skillset {
  color: #333;
  margin-right: 5px;
}
.skillset h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 20px 0 10px;
}
@media (max-width: 768px) {
  .skillset h2 {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}
.skillset .skillCtn {
  width: 100%;
}
.skillset .skillCtn .progress-bar {
  width: 100%;
  height: 32px;
  background-color: #ddd;
  margin-bottom: 10px;
  position: relative;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .skillset .skillCtn .progress-bar {
    height: 20px;
    font-size: 0.8rem;
  }
}
.skillset .skillCtn .progress-bar .progress-bar-fill {
  height: 100%;
  position: relative;
}
.skillset .skillCtn .progress-bar .skill-name {
  position: absolute;
  top: 0;
  width: 100%;
  line-height: 32px;
  text-align: center;
  color: #666;
}
@media (max-width: 768px) {
  .skillset .skillCtn .progress-bar .skill-name {
    line-height: 20px;
  }
}
