.subpageH1 {
    position: relative;
    display: inline-block;
    z-index: 0;
    margin: 0;
    padding-right: 10px;
    font-size: 4.5rem;
    line-height: 6rem;
    color: #000;
    font-weight: 500;
    text-shadow: 2px 2px 0 white;

    @media (max-width: 768px) {
        font-size: 3.5rem;
        line-height: 5rem;
    }

    @media (max-width: 480px) {
        font-size: 3rem;
        line-height: 4rem;
    }

    .bar {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: -1;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        transition: background 1.5s ease-in-out;
    }
}

.qrcode {
    position: absolute;
    top: 100%;
    right: 0;
    width: 130px;
    height: 145px;
    overflow: hidden;
    transition: 0.5s all ease-in-out;
    &.hide {
        height: 0;
        opacity: 0;
    }
    &:before {
        content: "";
        position: absolute;
        top: -5px;
        right: 1px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent white transparent;
    }

    img {
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
    }
}