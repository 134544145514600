.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.HomePage {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  color: #333;
  font-family: var(--primary-font);
}
.HomePage .linkBtn {
  display: inline-block;
  font-family: var(--primary-font);
  margin: 0 20px 10px 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #666;
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-shadow: 2px 0 0 #fff;
}
@media (max-width: 480px) {
  .HomePage .linkBtn {
    font-size: 1rem;
    line-height: 1rem;
  }
}
.HomePage .linkBtn:hover {
  opacity: 1;
}
.HomePage .white {
  flex: 1;
  background: white;
  transition: all 0.5s ease-in-out;
  padding: 40px 40px 50px;
}
@media (max-width: 480px) {
  .HomePage .white {
    padding: 20px 20px 50px;
  }
}
.HomePage .white.subpage {
  flex: 2;
}
@media (max-width: 480px) {
  .HomePage .white.subpage {
    flex: 3;
  }
}
.HomePage .white .content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
}
.HomePage .bgWrapper {
  flex: 2;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
}
@media (max-width: 480px) {
  .HomePage .bgWrapper {
    flex: 3;
  }
}
.HomePage .bgWrapper.subpage {
  flex: 1;
}
.HomePage .bg {
  width: 100%;
  height: 100%;
  background: white no-repeat center center / cover;
  scale: 1.05;
  transition: all 0.2s ease-out;
}
.HomePage .homeContent {
  position: absolute;
  top: 50%;
  left: 40px;
  right: 40px;
  transform: translateY(-60%);
  text-align: left;
}
@media (max-width: 480px) {
  .HomePage .homeContent {
    left: 20px;
    right: 20px;
  }
}
.HomePage .homeContent h1 {
  margin: 0;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
  color: #000;
}
@media (max-width: 768px) {
  .HomePage .homeContent h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .HomePage .homeContent h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.HomePage .homeContent h1 .highlight {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}
.HomePage .homeContent h1 .highlight .bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.HomePage .homeContent h2 {
  margin: 0;
  font-size: 4.5rem;
  line-height: 4.5rem;
  color: #000;
  font-weight: 500;
}
@media (max-width: 768px) {
  .HomePage .homeContent h2 {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}
@media (max-width: 480px) {
  .HomePage .homeContent h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.HomePage .homeContent .subtitleContainer {
  margin: 30px 0 0;
}
@media (max-width: 480px) {
  .HomePage .homeContent .subtitleContainer {
    margin-top: 15px;
  }
}
.HomePage .homeContent .subtitle {
  font-family: var(--secondary-font);
  font-size: 1.8rem;
  line-height: 2rem;
  padding-right: 5px;
  color: #333;
  background: linear-gradient(to bottom, transparent 40%, #fff 40%, #fff 80%, transparent 80%);
  background-repeat: no-repeat;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 480px) {
  .HomePage .homeContent .subtitle {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
.HomePage .homeContent .subtitle .highlight {
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  padding: 0px 5px;
  z-index: 0;
}
.HomePage .homeContent .subtitle .highlight .bar {
  position: absolute;
  bottom: 0;
  left: 2px;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.HomePage .homeContent .arrows {
  margin: 10px 0;
  display: inline-block;
}
.HomePage .homeContent .arrows span {
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  text-shadow: 2px 2px 0 #666;
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 480px) {
  .HomePage .homeContent .arrows span {
    font-size: 1rem;
    line-height: 1rem;
  }
}
.HomePage .homeContent .arrows span:hover {
  opacity: 1;
}
.HomePage .homeContent .links {
  margin-top: 30px;
  height: 40px;
}
@media (max-width: 480px) {
  .HomePage .homeContent .links {
    margin-top: 15px;
    height: 30px;
  }
}
.HomePage .homeContent .links .wechatQrCtn {
  position: relative;
  display: inline-block;
}
.HomePage .homeContent .links .wechatQrCtn .qrcode {
  top: -10px;
  left: 100%;
}
.HomePage .homeContent .links .wechatQrCtn .qrcode.hide {
  width: 0;
  opacity: 0;
}
.HomePage .homeContent .links .wechatQrCtn .qrcode:before {
  display: none;
}
.HomePage .homeContent .links .wechatQrCtn .qrcode img {
  border: 1px solid #ddd;
}
