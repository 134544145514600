.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.CV {
  color: #333;
  margin-right: 5px;
}
.CV h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 0 0 10px 0;
  border-bottom: 2px solid #ddd;
  color: #000;
  margin-bottom: 20px !important;
}
@media (max-width: 768px) {
  .CV h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media (max-width: 480px) {
  .CV h2 {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-bottom: 10px !important;
  }
}
.CV .section {
  margin: 10px 0 30px;
}
@media (max-width: 768px) {
  .CV .section {
    margin: 10px 0 20px;
  }
}
.CV .periodCtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .CV .periodCtn {
    flex-direction: column;
    align-items: start;
  }
}
.CV h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 5px;
}
@media (max-width: 768px) {
  .CV h3 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.CV h3 img.logo {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .CV h3 img.logo {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5px;
  }
}
.CV p {
  font-size: 1.2rem;
  margin: 2px 0;
}
.CV p.big {
  font-weight: bold;
}
.CV .detail {
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  margin: 10px 0 15px;
  color: #666;
}
.CV .detail.middle {
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .CV .detail {
    font-size: 1rem;
    margin: 0px 0 20px;
  }
  .CV .detail.middle {
    margin-bottom: 10px;
  }
}
