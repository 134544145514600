.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.projects {
  color: #333;
  margin-right: 5px;
  position: relative;
}
.projects h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 20px 0 10px;
}
@media (max-width: 768px) {
  .projects h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media (max-width: 480px) {
  .projects h2 {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}
.projects .sectionCtn {
  display: flex;
  flex-wrap: wrap;
}
.projects .sectionCtn .item {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: start;
  }
}
.projects .sectionCtn .item:hover .itemBg {
  opacity: 0.8;
}
.projects .sectionCtn .item .sliderCtn {
  width: 50%;
  border: 5px solid #eee;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .sliderCtn {
    width: 100%;
  }
}
.projects .sectionCtn .item .imageCtn {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.67%;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .imageCtn {
    width: 100%;
    padding-bottom: 66.67%;
  }
}
.projects .sectionCtn .item .imageCtn:hover .zoom {
  opacity: 1;
}
.projects .sectionCtn .item .imageCtn .itemBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.projects .sectionCtn .item .imageCtn .zoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 2rem;
  color: #fff;
}
.projects .sectionCtn .item .detail {
  width: 50%;
  height: 100%;
  padding: 0 10px;
  position: relative;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .detail {
    width: 100%;
    padding: 5px 0 10px;
  }
}
.projects .sectionCtn .item .detail .title {
  width: 100%;
  max-width: 100%;
  color: #333;
  box-sizing: border-box;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .detail .title {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.projects .sectionCtn .item .detail .desc {
  color: #666;
  font-size: 1.2rem;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .detail .desc {
    font-size: 1rem;
  }
}
.projects .sectionCtn .item .detail .link {
  display: block;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: var(--secondary-font);
  font-size: 1rem;
  color: #666;
  opacity: 0.75;
  transition: all 0.3s;
}
.projects .sectionCtn .item .detail .link:hover {
  opacity: 1;
}
@media (max-width: 768px) {
  .projects .sectionCtn .item .detail .link {
    margin-top: 10px;
    position: relative;
    font-size: 0.8rem;
  }
}
.projects .zoomedCarouselBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.projects .zoomedCarouselBackdrop .slideCtn .imageCtn {
  height: 50vh;
  display: flex;
  align-items: center;
}
.projects .zoomedCarouselBackdrop .slideCtn .imageCtn img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.projects .zoomedCarouselBackdrop .slideCtn .carousel .thumbs {
  padding-left: 0;
  white-space: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.projects .zoomedCarouselBackdrop .slideCtn .carousel .thumb {
  border: 3px solid #444;
  border-radius: 5px;
  margin-bottom: 6px;
}
.projects .zoomedCarouselBackdrop .slideCtn .carousel .thumb.selected {
  border: 3px solid #ddd;
}
.projects .zoomedCarouselBackdrop .closeButton {
  position: absolute;
  background-color: transparent;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 2rem;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 480px) {
  .projects .zoomedCarouselBackdrop .closeButton {
    font-size: 1.5rem;
  }
}
.projects .zoomedCarouselBackdrop .closeButton:hover {
  opacity: 1;
}
