.subpageH1 {
  position: relative;
  display: inline-block;
  z-index: 0;
  margin: 0;
  padding-right: 10px;
  font-size: 4.5rem;
  line-height: 6rem;
  color: #000;
  font-weight: 500;
  text-shadow: 2px 2px 0 white;
}
@media (max-width: 768px) {
  .subpageH1 {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
@media (max-width: 480px) {
  .subpageH1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.subpageH1 .bar {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background 1.5s ease-in-out;
}
.qrcode {
  position: absolute;
  top: 100%;
  right: 0;
  width: 130px;
  height: 145px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}
.qrcode.hide {
  height: 0;
  opacity: 0;
}
.qrcode:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 1px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.qrcode img {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}
.Header {
  position: absolute;
  z-index: 100;
  right: 0;
  padding: 10px 20px;
}
@media (max-width: 480px) {
  .Header {
    padding: 10px;
  }
}
.Header nav button {
  margin: 0 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}
.Header .language-switcher {
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .Header .language-switcher {
    display: block;
    margin-bottom: 10px;
    margin-left: 0;
    width: 40px;
    text-align: center;
  }
}
.Header .language-switcher button {
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  line-height: 1rem;
  opacity: 0.75;
  transition: all 0.2s;
  padding: 0;
}
.Header .language-switcher button:hover {
  opacity: 1;
}
.Header .links {
  display: inline-block;
}
.Header .links span {
  position: relative;
  margin-left: 10px;
  font-size: 1rem;
  color: #fff;
  opacity: 0.75;
  transition: all 0.2s;
  text-shadow: 1px 1px 0 #333;
}
@media (max-width: 768px) {
  .Header .links span {
    display: block;
    margin-bottom: 10px;
    margin-left: 0;
    width: 40px;
    text-align: center;
  }
}
.Header .links span:hover {
  opacity: 1;
}
